import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./sections/CheckoutForm";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { useState, useEffect, useRef } from "react";

// Images
import bgImage from "assets/images/workoutdude.jpg";
import bgImage1 from "assets/images/workoutdude1.jpg";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from "@stripe/react-stripe-js";
import Product from "./sections/Product";
import MKInput from "components/MKInput";
import { Grid, Container } from "@mui/material";

function Checkout() {
  const location = useLocation();
  const stateValues = location.state;
  const stripePromise = loadStripe("pk_live_51NJFKkGzlBsVsjFLhLOIXLQFHOWy3zogiLO6qTpkMt8HExlzQU2ThcJp8Rl4b7b5cmCuywFTGChX1PDhKVhblRJ500lRMzwheF");
  const [clientSecret, setClientSecret] = useState("");
  const [orderId, setOrderId] = useState("");
  const [code, setCode] = useState("");
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [price, setPrice] = useState("");
  const [isFree, setIsFree] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();

  useEffect(() => {
    setClientSecret(stateValues.clientSecret);
    setOrderId(stateValues.orderId);
    setPrice(stateValues.price/100)
  }, []);

  const getBaseURL = () => {
    if (process.env.NODE_ENV == 'production') {
      return process.env.REACT_APP_BASE_URL_SERVER_PROD;
    } else {
      return process.env.REACT_APP_BASE_URL_SERVER;
    }
  }

  const checkCodeButton = () => {
    fetch(getBaseURL() + "/order/" + orderId + "/code", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: code
    }).then(async (result) => {
      var { piClientSecret, orderId, price } = await result.json();
      if (piClientSecret == "free"){
        setIsFree(true);
        setPrice("Free")
      }
      else if (piClientSecret != null){
        setClientSecret(piClientSecret);
        setOrderId(orderId);
        setPrice(price/100);
        setCodeInvalid(false)
        console.log(price);
      }
      else{
        console.log("invalidddd: " + piClientSecret);
        console.log("100% discount")
        setCodeInvalid(true);
      }
    });
  }

  const completeFreeCheckout = () => {
    navigate("/completion?orderId=" + orderId);
  }

  const handleInputChange = (event) => {
    console.log(event)
    setCode(event.target.value);
  }

  return (
    <>
      <MKBox
      minHeight="75vh"
      sx={{
        backgroundImage: `url(${bgImage1})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
        justifyContent:"center"
      }}
      >
        <Container alignItems='center'>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" minWidth="800px">
            <MKTypography
                variant="h1"
                color="white"
                textAlign="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  fontFamily: 'Roboto, sans-serif', // Set the desired font family here
                  mt: { xs: 0, lg: 0 },
                })}
              >
                Workout Plan AI
              </MKTypography>
          </Grid>
          <Grid container spacing={2} item xs={12} lg={12} p={2} justifyContent="center" mx="auto">
            <Grid item xs={3}>
              <MKInput onChange={handleInputChange} name="code" value={code} placeholder="Enter Discount Code" 
              sx={{ background: 'white' }}>
              </MKInput>
            </Grid>
            <Grid item xs={3}>
              <MKButton onClick={checkCodeButton} variant="gradient" color="info"> submit Code</MKButton>
              {codeInvalid && ("Code Invalid!")}
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={9} justifyContent="center" mx="auto" maxWidth={windowSize.current[0]}>
            {price && (<Product price={price}/> )}
          </Grid>
          {!isFree?
          (<Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            {clientSecret && stripePromise && price && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm price={price} orderId={orderId}/>
              </Elements>
            )}
            </Grid>) :
            (<Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                <MKButton variant="gradient" color="info" onClick={() => completeFreeCheckout()}>
                  Create free plan
                </MKButton>
            </Grid>)
            }
          </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Checkout;
