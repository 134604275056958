import { useNavigate } from "react-router-dom";
import "./index.css";

function BackButton(){

    const navigate = useNavigate();

    const goToHomepage = () => {
        navigate("/")
    }

    return (
        <button class="back-button" onClick={() => goToHomepage()}>
        <svg width="60px" height="60px" viewBox="0 0 60 60" class="border-svg">
          <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
          <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
        </svg>
        <span> Back</span>
      </button>
    )
}

export default BackButton;