// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import { alpha } from '@mui/material';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import AICounters from "pages/StartPage/sections/AICounters";
import Testimonials from "pages/StartPage/sections/Testimonials";

// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/workoutdude.jpg";
import InputStart from "./sections/InputStart";

// Cookie
import CookieConsent from "react-cookie-consent";
import { useCookies } from 'react-cookie'

import "./button.css";

// react
import { useEffect } from "react";

function StartPage() {
  // example affiliate link: http://localhost:3000/?affiliate=zFyZUcJTbd
  const [cookies, setCookie] = useCookies(['affiliateCode'])

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const paramAffiliateCode = params.affiliate;

  useEffect(() => {
    if (paramAffiliateCode!=null){
        let expires = new Date()
        expires.setTime(expires.getTime() + (90 * 24 * 60 * 60 * 1000)) // approximately expires cookie after 90 days
        setCookie("affiliateCode", paramAffiliateCode, { path: '/',  expires})
        console.log(paramAffiliateCode)
    }
  }, []);

  const handleClickScroll = () => {
    const element = document.getElementById("inputTarget");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  return (
    <>
      <MKBox
        minHeight="75vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={8} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                fontFamily: 'Roboto, sans-serif', // Set the desired font family here
                mt: { xs: -8, lg: -11 },
              })}
            >
              Workout Plan AI
            </MKTypography>
            <Box backgroundColor={alpha("#212121", 0.6)}>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
              >
              <div>Create your own fully customized AI workout plan!</div>
              <div>Perfectly tailored to your need, whether working out at home, at the gym or outdoors.</div>
              </MKTypography>
            </Box>
          </Grid>
        </Container>


        <button class="btn-custom" onClick={() => handleClickScroll()}>
        <svg width="180px" height="60px" viewBox="0 0 180 60" class="border-svg">
          <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
          <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
        </svg>
        <span>CREATE PLAN!</span>
      </button>


      </MKBox>
      <Card
        sx={{
          // p: 2,
          // mx: { xs: 8, lg: 8 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { black }, functions: { rgba } }) => rgba(black.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <AICounters />
        <div id="inputTarget">
          <InputStart affiliateCode={paramAffiliateCode?paramAffiliateCode:cookies.affiliateCode}/>
        </div>
        {/*<Testimonials /> */}
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We continuously improve our product
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="consent-cookie"
        style={{ background: "#2B373B", textAlign: "center"}} // , width: "70%"
        buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </>
  );
}

export default StartPage;
