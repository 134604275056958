// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/workoutdude.jpg";
import bgImage1 from "assets/images/workoutdude1.jpg";


// React
import { useRef } from "react";
import BackButton from "components/BackButton";

function AboutUs() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

    return (
      <>
      <BackButton/>
        <MKBox
          minHeight="75vh"
          width="100%"
          minWidth={windowSize.current[0]}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            height: "auto"
          }}
        >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-11}
              mb={20}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              About Us
            </MKTypography>
          </Grid>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={-3}
            >
              Our aim is to make customized workout plans available for all budgets.
              If you have any questions or wish to contact us: team@workoutplanai.com
            </MKTypography>
          </Grid>
        </Container>
        </MKBox>
          
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </>
    );
  }
  export default AboutUs;