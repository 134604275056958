import sampleImage from "assets/images/fitness.png";
import "./product.css"
import { Container } from "@mui/material";
import Grid from '@mui/material/Grid';

function Product(props) {
    return (
        <>
            <Grid container className="product" alignItems="center" justifyItems="center">
                <Grid item xs={2} className="col-md-2" justifyItems="center">
                    <img src={sampleImage} alt="Sample Image" height="90"/>
                </Grid>
                <Grid xs={8} className="col-md-8 product-detail">
                    <h4>Workout Plan</h4>
                    <h5>Fully customized AI generated Workout Plan</h5>
                </Grid>
                <Grid xs={2} className="col-md-2 product-price">
                    ${props.price}
                </Grid>
            </Grid>
        </>
        
    );
  }

  export default Product;