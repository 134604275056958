// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function AICounters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={90}
              suffix="%+"
              title="Cheaper"
              description="Than any other custom workout plan"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={50}
              suffix="+ TB"
              title="Data"
              description="Was used to create the AI model"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={5}
              suffix="+"
              title="Pages"
              description="Of a highly customized workout plan just for you"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AICounters;
