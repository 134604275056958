// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import ToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { useTheme } from '@mui/material/styles';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import colors from "assets/theme/base/colors";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

function InputStart(props) {
  const [state, setState] = useState({
    mail: "",
    gender: "MALE",
    sportLevel: 5,
    gymLevel: 5,
    workoutPlaces: ["GYM"],
    availability: [1, 3],
    disability: false,
    typeOfDisability: "",
    pain: false,
    typeOfPain: "",
    goal: "",
    timeframe: 3,
    additionalRequests: "",
    affiliateCode: props.affiliateCode
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showMailError, setShowMailError] = useState(false);
  
  const getBaseURL = () => {
    if (process.env.NODE_ENV == 'production') {
      return process.env.REACT_APP_BASE_URL_SERVER_PROD;
    } else {
      return process.env.REACT_APP_BASE_URL_SERVER;
    }
  }


  const loadPlan = (state) => {
    setIsLoading(!isLoading)
    console.log(state)
    fetch(getBaseURL() + "/order", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state)
    })
    .then(async (result) => {
        if (result.status == 200){
        var { piClientSecret, orderId, price } = await result.json();
        console.log("orderId: " + orderId);
        goToCheckout(piClientSecret, orderId, price);
      } else {
        //todo: do some more error handling, i.e. show error in mail field
        setIsLoading(false);
      }
    });
  }
  
  const goToCheckout = (piClientSecret, orderId, price) => {
    if (isLoading){
      setIsLoading(false);
    }
    navigate("/checkout", {
      state: {
        clientSecret: piClientSecret, 
        orderId: orderId,
        price: price
      }
    });
  };

  const theme = useTheme();

  const StyledToggleButton3 = styled(ToggleButton)(({ theme }) => ({
    width: "33.3%",
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor:colors.info.main
    },
    '&.Mui-selected': {
      backgroundColor: colors.info.main
    }
  }));

  
  const StyledToggleButton2 = styled(ToggleButton)(({ theme }) => ({
    width: "50%",
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor:colors.info.main
    },
    '&.Mui-selected': {
      backgroundColor: colors.info.main
    }
  }));

  // gender toggle

  const onGenderOptionClicked = (value) => () => {
    setState({
      ...state,
      gender: value,
    });
  };

  const isGenderSelected = (thisGender, selectedGender) => {
      return thisGender === selectedGender;
  }

  // workout place toggle

  const onPlaceOptionClicked = (value, selectedPlaces) => () => {
    if (selectedPlaces.includes(value)){
      selectedPlaces.splice(selectedPlaces.indexOf(value), 1)
      setState({
        ...state,
        workoutPlaces: selectedPlaces,
      });
    }
    else {
      console.log("ADDING element TO array")
      selectedPlaces.push(value);
      setState({
        ...state,
        workoutPlaces: selectedPlaces,
      }); 
    }
  };

  const isPlaceSelected = (value, selectedPlaces) => {
    return selectedPlaces.includes(value);
}

  // disability toggle
  const onDisabilityOptionClicked = (value) => () => {
    setState({
      ...state,
      disability: value,
    });
  };

  // pain toggle
  const onPainOptionClicked = (value) => () => {
    setState({
      ...state,
      pain: value,
    });
  };

  const timeframeMarks = [
    {  value: 1, label: "1"},
    {  value: 2, label: "2",},
    {  value: 3, label: "3",},
    {  value: 4, label: "4",},
    {  value: 5, label: "5",},
    {  value: 6, label: "6",},
    {  value: 7, label: "7",},
    {  value: 8, label: "8",},
    {  value: 9, label: "9",},
    {  value: 10, label: "10",},
    {  value: 11, label: "11",},
    {  value: 12, label: "12",},
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  // todo create toggle button instead of dropdown
  return (
    <MKBox component="section" py={0} my={0}>
      <Container>
        <Grid
          item
          xs={12}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
          justifyContent="center"
        >
          <Grid item xs={12} lg={12}>
            <MKBox p={2}>
              <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                <MKTypography variant="h2" mb={1} textAlign="center">
                  AI Workout Plan
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={2} textAlign="center">
                  We need some information about you to create a highly customized workout plan for
                  you! The more you submit, the more accurate we can create it for you.
                </MKTypography>
              </MKBox>
              <MKBox pb={3} px={3}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                  <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Please select your gender:
                    </MKTypography>
                    <StyledToggleButton3
                      value="check"
                      selected={isGenderSelected("MALE", state.gender)}
                      onClick={onGenderOptionClicked("MALE")}
                    >
                      Male
                    </StyledToggleButton3>
                    <StyledToggleButton3
                      value="check"
                      selected={isGenderSelected("FEMALE", state.gender)}
                      onClick={onGenderOptionClicked("FEMALE")}
                    >
                      Female
                    </StyledToggleButton3>
                    <StyledToggleButton3
                      value="check"
                      selected={isGenderSelected("OTHER", state.gender)}
                      onClick={onGenderOptionClicked("OTHER")}
                    >
                      Other
                    </StyledToggleButton3>
                  </Grid>
                  <Grid item xs={12}>
                  <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Please select where you would like to workout:
                    </MKTypography>
                    <StyledToggleButton3
                      value="check"
                      selected={isPlaceSelected("GYM", Object.values(state.workoutPlaces))}
                      onClick={onPlaceOptionClicked("GYM", Object.values(state.workoutPlaces))}
                    >
                      Gym
                    </StyledToggleButton3>
                    <StyledToggleButton3
                      value="check"
                      selected={isPlaceSelected("HOME", Object.values(state.workoutPlaces))}
                      onClick={onPlaceOptionClicked("HOME", Object.values(state.workoutPlaces))}
                    >
                      Home
                    </StyledToggleButton3>
                    <StyledToggleButton3
                      value="check"
                      selected={isPlaceSelected("OUTDOOR", Object.values(state.workoutPlaces))}
                      onClick={onPlaceOptionClicked("OUTDOOR", Object.values(state.workoutPlaces))}
                    >
                      Outdoors
                    </StyledToggleButton3>
                  </Grid>
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Please select how advanced you are in the gym. 0 = Never been to a gym. 10 =
                      Highly advanced and experienced.
                    </MKTypography>
                    <Slider
                      name="gymLevel"
                      get-aria-label="gymLevel"
                      defaultValue={state.gymLevel}
                      value={state.gymLevel}
                      get-aria-value-text="valuetext"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={10}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Please select how advanced overall you are at sports. 0 = I have never done
                      any sports. 10 = Highly advanced and experienced.
                    </MKTypography>
                    <Slider
                      name="sportLevel"
                      get-aria-label="sportLevel"
                      defaultValue={state.sportLevel}
                      value={state.sportLevel}
                      get-aria-value-text="valuetext"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={10}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      How often per week are you available to go to the gym? (1 to 3 means between
                      once to three times a week)
                    </MKTypography>
                    <Slider
                      name="availability"
                      get-aria-label="availability"
                      defaultValue={state.availability}
                      value={state.availability}
                      get-aria-value-text="valuetext"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={7}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Do you suffer from any disabilities?
                    </MKTypography>
                    <StyledToggleButton2
                      value="check"
                      selected={state.disability}
                      onClick={onDisabilityOptionClicked(true)}
                    >
                      Yes
                    </StyledToggleButton2>
                    <StyledToggleButton2
                      value="check"
                      selected={!state.disability}
                      onClick={onDisabilityOptionClicked(false)}
                    >
                      No
                    </StyledToggleButton2>
                  </Grid>
                  {state.disability ? (
                    <Grid item xs={12} pr={1}>
                      <MKInput
                        variant="outlined"
                        label="What kind of disability?"
                        onChange={handleChange}
                        name="typeOfDisability"
                        value={state.typeOfDisability}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Do you suffer from any persistent pain?
                    </MKTypography>
                    <StyledToggleButton2
                      value="check"
                      selected={state.pain}
                      onClick={onPainOptionClicked(true)}
                    >
                      Yes
                    </StyledToggleButton2>
                    <StyledToggleButton2
                      value="check"
                      selected={!state.pain}
                      onClick={onPainOptionClicked(false)}
                    >
                      No
                    </StyledToggleButton2>
                  </Grid>
                  {state.pain ? (
                    <Grid item xs={12}>
                      <MKInput
                        variant="outlined"
                        label="What type of pain?"
                        onChange={handleChange}
                        name="typeOfPain"
                        value={state.typeOfPain}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Do you have any goals you want to achieve?
                    </MKTypography>
                    <MKInput
                      variant="outlined"
                      onChange={handleChange}
                      name="goal"
                      value={state.goal}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                      Do you have a specific duration in which you would like to achieve your goals (in months)?
                    </MKTypography>
                    <Slider
                      name="timeframe"
                      get-aria-label="timeframe"
                      defaultValue={state.timeframe}
                      value={state.timeframe}
                      get-aria-value-text="valuetext"
                      valueLabelDisplay="auto"
                      step={1}
                      marks={timeframeMarks}
                      min={1}
                      max={12}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                  Do you have any additional requests for us?
                    </MKTypography>
                    <MKInput
                      variant="outlined"
                      placeholder="List equipment available at home, etc..."
                      onChange={handleChange}
                      name="additionalRequests"
                      value={state.additionalRequests}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <MKTypography variant="body2" color="text" mb={2} textAlign="center">
                    Please input your mail address where we can send you your workout plan
                    </MKTypography>
                    <MKInput
                      variant="outlined"
                      onChange={handleChange}
                      name="mail"
                      value={state.mail}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={showMailError}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <button class="btn-custom" onClick={() => loadPlan(state)}>
                      <svg width="180px" height="60px" viewBox="0 0 180 60" class="border-svg">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>{isLoading?("Loading ..."):("SUBMIT")}</span>
                    </button>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InputStart;
