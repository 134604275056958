// loading animation
import { Roller } from "react-awesome-spinners";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage1 from "assets/images/workoutdude1.jpg";

// React
import { useEffect, useState } from "react";
import BackButton from "components/BackButton";

// http://localhost:3000/completion?payment_intent=pi_3NKfQwGzlBsVsjFL1b74ec4w&payment_intent_client_secret=pi_3NKfQwGzlBsVsjFL1b74ec4w_secret_IRk5FoZmn3hS0kX9F6r93KKUL&redirect_status=succeeded

function Completion(props) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const [completed, setCompleted] = useState(false)

    const getBaseURL = () => {
      if (process.env.NODE_ENV == 'production') {
        return process.env.REACT_APP_BASE_URL_SERVER_PROD;
      } else {
        return process.env.REACT_APP_BASE_URL_SERVER;
      }
    }

    useEffect(() => {
      // Update the document title using the browser API
      if (!completed){
        fetch(getBaseURL() + "/order/" + params.orderId + "/complete", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            paymentIntentId: params.payment_intent,
            paymentIntentCS : params.payment_intent_client_secret 
          })
        })
        .then(async (result) => {
          console.log(result);
          var json = await result.json();
          (json==params.orderId)?setCompleted(true):setCompleted(false)
        });
      }
    });

    return (
          <>
          <BackButton/>
          <MKBox
            minHeight="75vh"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.6),
                  rgba(gradients.dark.state, 0.6)
                )}, url(${bgImage1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
              height: "auto"
            }}
          >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                mt={-11}
                mb={20}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Thank you! 🎉 
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={-3}
              >
                <div>{completed?(<><h2>Order completed</h2> <h5>Don't forget to check your spam folder </h5></>):(<Roller />)}</div>
                <div>{completed?(""):("Completing Order...")}</div>
                <h5>Any problems with your order? </h5>
                <h5>Contact: team@workoutplanai.com</h5>
              </MKTypography>
            </Grid>
          </Container>
          </MKBox>
            
          <MKBox pt={6} px={1} mt={6}>
            <DefaultFooter content={footerRoutes} />
          </MKBox>
        </>
    )
  }
  
  export default Completion;