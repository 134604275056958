// @mui icons
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from "@mui/icons-material/Twitter";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import fitnessLogo from "assets/images/workoutplanai/fitness.png";
import Impressum from 'pages/Impressum';
import AboutUs from 'pages/AboutUs';
import Affiliate from 'pages/Affiliate';
import StartPage from 'pages/StartPage';

const date = new Date().getFullYear();

export default {
  brand: {
    name: "WorkoutPlanAI",
    image: fitnessLogo,
    route: "/",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/workoutplanai/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/workoutplanai",
    },
  ],
  menus: [
    {
      name: "Resources",
      items: [
        { name: "Homepage", route: "/", component: StartPage },
        { name: "Affiliate Program", route: "/affiliate", component: Affiliate }]
    },
    {
      name: "Company",
      items: [
        { name: "About Us", route: "/about", component: AboutUs }, 
        {name: "Impressum", route: "/impressum", component: Impressum }, 
        {name: "Contact", route: "/about", component: AboutUs }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} AI workout plan generator by{" "}
      <MKTypography
        component="a"
        href="http://workoutplanai.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        WorkoutPlanAI
      </MKTypography>
      .
    </MKTypography>
  ),
};
