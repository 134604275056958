import React, { useRef, useState } from "react";
import { Container, Grid } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton
 from "components/MKButton";
// Images
import bgImage1 from "assets/images/workoutdude1.jpg";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";
import BackButton from "components/BackButton";

function Affiliate() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [mail1, setMail1] = useState(null);
  const [mail2, setMail2] = useState(null);
  const [mail1Loading, setMail1Loading] = useState(false);
  const [mail2Loading, setMail2Loading] = useState(false);
  const [mail1Answer, setMail1Answer] = useState(null);
  const [mail2Answer, setMail2Answer] = useState(null);

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    setMail1(value);
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setMail2(value);
  };

  const becomeAffiliate = (mail1) => {
    setMail1Loading(true)
    fetch(getBaseURL() + "/affiliate", {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: mail1
    })
    .then(async (result) => {
        setMail1Loading(false)
        if (result.status == 200){
        var mail1Answer = await result.text();
        setMail1Answer(mail1Answer);
        console.log(mail1Answer);
      } else {
        //todo: do some more error handling, i.e. show error in mail field
        
      }
    });
  }

  const getAffiliateInfo = (mail2) => {
    setMail2Loading(true)
    fetch(getBaseURL() + "/affiliate?mail=" + mail2, {
      method: "GET",
      headers: { "Content-Type": "text/plain" },
    })
    .then(async (result) => {
        setMail2Loading(false)
        if (result.status == 200){
        var mail2Answer = await result.text();
        setMail2Answer(mail2Answer);
        console.log(mail2Answer);
      } else {
        //todo: do some more error handling, i.e. show error in mail field
        
      }
    });
  }

  const getBaseURL = () => {
    if (process.env.NODE_ENV == 'production') {
      return process.env.REACT_APP_BASE_URL_SERVER_PROD;
    } else {
      return process.env.REACT_APP_BASE_URL_SERVER;
    }
  }

  return (
    <>
      <BackButton/>
      <MKBox
        minHeight="75vh"
        width="100%"
        minWidth={windowSize.current[0]}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          height: "auto"
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} p={3} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                fontFamily: 'Roboto, sans-serif'
              })}
            >
              Affiliate Program
            </MKTypography>
          </Grid>
          <Grid container item xs={12} lg={8} justifyContent="center" mx="auto" placeItems="center">
            <MKTypography
              variant="body2"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
            >
                <Grid item xs={12} p={2}>
                    <div>Do you want to become an affiliate? Our affiliates receive a 50% comission on each sale*. Please only use an email address that is also connected to your paypal account.<MKTypography/></div>
                    <Grid item xs={12}>
                        <MKInput
                                variant="standard"
                                label="Email Address"
                                onChange={handleChange1}
                                name="mail1"
                                value={mail1}
                                fullWidth
                                style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', color: 'black' }}
                            />
                    </Grid>
                    <Grid item xs={12} p={2}>
                        <MKButton variant="gradient" color="info" onClick={() => becomeAffiliate(mail1)}>
                        {mail1Loading?("Loading..."):"Become an Affiliate"}
                        </MKButton>
                    </Grid>
                    {(mail1Answer?(<Grid item xs={12} p={2}><div>{mail1Answer}</div></Grid>):<></>)}
                </Grid>
                <Grid item xs={12} p={2}>
                    <div>Already an affiliate? Receive your current affiliate statistics per email:</div>
                    <Grid item xs={12}>
                        <MKInput
                                variant="standard"
                                label="Email Address"
                                onChange={handleChange2}
                                name="mail2"
                                value={mail2}
                                fullWidth
                                style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', color: 'black' }}
                        />
                    </Grid>
                    <Grid item xs={12} p={2}>
                        <MKButton variant="gradient" color="info" onClick={() => getAffiliateInfo(mail2)}>
                        {mail2Loading?("Loading..."):"Get your stats"}
                        </MKButton>
                    </Grid>
                    {(mail2Answer?(<Grid item xs={12} p={2}><div>{mail2Answer}</div></Grid>):<></>)}
                </Grid>
                </MKTypography>
            </Grid>
        </Container>
      </MKBox>
      <MKBox component="section">
      <Container>
        <Grid
          item
          xs={12}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
          justifyContent="center"
        >
                    <MKTypography
                        variant="button"
                        color="black"
                        px={{ xs: 6, lg: 12 }}
                        >
                            <div>*As an affiliate, you will earn a commission for every workout plan when your affiliate link is used. We recommend incorporating the affiliate link strategically across your website, blog, social media platforms, or any other channels you utilize to engage with your audience. The more you promote and share this link, the higher your chances of earning commissions. </div>

                            <div>The commission you receive is exactly 50 percent (after fees). As an example, a workout plan for $9.99 if bought in the US, might cost $0.70 in value added tax and then our payment provider Stripe has an additional fee of $0.21, creating a commission for you of $4.54. </div> 
                            
                            <div>Payments are done at the end of every month to every account having more than $10 earned in commission (2-3 purchases). All payments are distributed to this exact mail address through PayPal. </div>

                            <div>If you want to see how much in commission you have earned or some more information, put your mail into the "receive information" field on www.workoutplanai.com/affiliate. </div>

                            <div>If you have any questions, need assistance, or seek customized promotional materials, please don't hesitate to reach out to our dedicated affiliate support team at marketing@workoutplanai.com. </div>
                    </MKTypography>
            </Grid>
            </Container>
            </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Affiliate;